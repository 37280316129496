const dataAbout = [
  {
    id: 1,
    title: "DEPLOYER DAPP",
    text: "Seamlessly deploy ERC404 tokens through our dapp or telegram bot. Input token details, and deploy with ease.",
  },
  {
    id: 2,
    title: "Launchpad for ERC404",
    text: "Offers full token launch services, including public minting, presale, and liquidity management—a single solution for ERC404 token issuance.",
  },
  {
    id: 3,
    title: "ERC404 Marketplace",
    text : 'Trade ERC404 tokens and NFTs in a user-friendly marketplace, enhancing liquidity and accessibility.'
  },
  {
    id: 4,
    title: "ERC404 Extensions",
    text : 'Customize your ERC404 tokens with advanced features like transaction tax, transaction limits, and rarity types.'
  },
];

export default dataAbout;
