


const dataFaqs = [
    {
        id: 1,
        title: 'What is 0x404 and how does it relate to ERC404 tokens?',
        text : '0x404 is a comprehensive toolkit designed specifically for the ERC404 token standard. It includes a range of features like a Deployer DApp, a Telegram Deployer Bot, a Metadata Generation Tool, a Launchpad for token deployment and trading, a specialized ERC404 Marketplace, and advanced buy bots.',
        show: 'show'
    
    },
    {
        id: 2,
        title: 'What is ERC404?',
        text : 'ERC404 is a token standard that integrates the liquidity and fractionalization features of ERC20 and ERC721 tokens. It provides a versatile framework for creating dynamic and customizable tokens, addressing the limitations of existing token standards.'
    },
    {
        id: 3,
        title: 'How can I deploy an ERC404 token using 0x404?',
        text : 'You can deploy ERC404 tokens easily using our Deployer DApp or the Telegram Deployer Bot. Both platforms are user-friendly and require you to connect your wallet and provide token details like name, symbol, and total supply. The process is designed to be straightforward, even for those with limited technical expertise.'
    },
    {
        id: 4,
        title: 'Are there any fees associated with using 0x404 services?',
        text : 'Yes, 0x404 charges a nominal deployment fee for using the Deployer DApp or Telegram Bot. Additionally, there are small fees associated with other features like the Metadata Generation Tool and transactions in the ERC404 Marketplace. These fees are essential for the sustainability and continuous development of the platform.'
    },
    {
        id: 5,
        title: 'What are the benefits of holding $0x404 tokens?',
        text : 'Holders of $0x404 tokens benefit from a 60% revenue share of the earnings from 0x404. This includes revenue generated from deployment fees, feature-based fees, premium services, advertisement on our Telegram services, and promotional activities on our launchpad. The revenue is distributed periodically and proportionally among the token holders.'
    },
    {
        id: 6,
        title: 'Will 0x404 expand to other blockchain networks?',
        text : "Yes, part of 0x404's vision is to expand its services to other Ethereum Virtual Machine (EVM) compatible chains. This expansion will broaden our reach and usability, making 0x404 a versatile and comprehensive toolkit in the blockchain space."
    },

   
]

export default dataFaqs;