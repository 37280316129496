import React, { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import "./styles.scss";

import logo from "../../assets/images/logo.png";
import CommingSoon from "../CommingSoon";

function Footer2(props) {
  const [isVisible, setIsVisible] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: "0",
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 500) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => window.removeEventListener("scroll", toggleVisibility);
  }, []);

  useEffect(() => {
    var timeout;
    const TemplateViewer = () => {
      // setShowTemplate(true);

      timeout = setTimeout(() => {
        setShowTemplate(false);
      }, 5000);
    };
    TemplateViewer();
    return () => clearTimeout(timeout);
  }, [showTemplate]);

  return (
    <footer
      id="contact"
      className="footer style-2"
      style={{ position: "relative" }}
    >
      <div
        style={{
          position: "absolute",
          display: showTemplate ? "block" : "none",
          zIndex: 9999,
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -530%",
        }}
      >
        <CommingSoon />
      </div>
      <div className="footer-inner">
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="title">JOIN OUR COMMUNITY</h2>
              {/* <p className="content">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.{" "}
              </p> */}
              <div className="group-btn">
                <a
                  href="https://t.me/eth0x404"
                  target="_blank"
                  className="tf-button"
                  data-toggle="modal"
                  data-target="#popup_bid"
                >
                  <i className="fab fa-telegram-plane"></i>
                  <span>TELEGRAM</span>
                </a>
                <a
                  href="https://0x404.gitbook.io/0x404/"
                  target="_blank"
                  className="tf-button style-2"
                  data-toggle="modal"
                  data-target="#popup_bid"
                >
                  <span>WHITEPAPER</span>
                </a>
              </div>
              <form action="#" id="subscribe-form">
                <input
                  type="email"
                  placeholder="Enter your email"
                  required=""
                  id="subscribe-email"
                />
                <button
                  className="tf-button"
                  type="submit"
                  id="subscribe-button"
                >
                  SUBSCRIBE
                </button>
              </form>

              <ul className="social-item">
                <li style={{ height: "7rem", width: "7rem" }}>
                  <a href="https://twitter.com/eth0x404" target="_blank">
                    <i className="fab fa-twitter fa-2x"></i>
                  </a>
                </li>
                <li style={{ height: "7rem", width: "7rem" }}>
                  <a target="_blank" href="https://medium.com/@0x404">
                    <i className="fab fa-medium fa-2x"></i>
                  </a>
                </li>
                <li style={{ height: "7rem", width: "7rem" }}>
                  <a target="_blank" href="https://t.me/eth0x404">
                    <i className="fab fa-telegram-plane fa-2x"></i>
                  </a>
                </li>
                <li style={{ height: "7rem", width: "7rem" }}>
                  <a
                    target="_blank"
                    href="https://www.youtube.com/channel/UCrxkUIDX1svPsX1bOZAtaGQ"
                  >
                    <i className="fab fa-youtube fa-2x"></i>
                  </a>
                </li>
                {/* <li>
                  <Link to="#">
                    <i className="icon-fl-tik-tok-2"></i>
                  </Link>
                </li> */}
                {/* <li>
                  <Link to="#">
                    <i className="icon-fl-vt"></i>
                  </Link>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="bottom-inner">
        <div className="tf-container">
          <div className="row">
            <div className="col-md-12">
              <div className="bottom">
                <div className="content-left">
                  <div className="logo-container">
                    <img
                      id="logo_header"
                      className="logo-dark"
                      src={logo}
                      alt="Binasea"
                    />
                    <div>0x404</div>
                  </div>
                  <p className="copy-right">0x404 2024 - ALL rights reserved</p>
                </div>

                <ul className="menu-bottom">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/about-v1">About</Link>
                  </li>
                  <li>
                    <Link to="/roadmap-v1">Roadmap</Link>
                  </li>
                  <li>
                    <Link to="/our-team">Our Team</Link>
                  </li>
                  <li>
                    <Link to="/faq-v1">FAQs</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isVisible && (
        <Link onClick={scrollToTop} to="#home" id="scroll-top"></Link>
      )}
    </footer>
  );
}

export default Footer2;
