



const dataRoadmap = [
    {
        id: 0,
        title: 'PHASE 1',
        class: 'left',
        list : [
            {
                id: 1,
                text: 'Conduct a detailed survey to understand problems around Erc 404',
            },
            {
                id: 2,
                text: 'Build the core team',
            },
            {
                id: 3,
                text: 'Develop strong product specifications',
            },
        ]
    
    },
    {
        id: 1,
        title: 'PHASE 2',
        class: 'right',
        list : [
            {
                id: 1,
                text: 'Launch of Deployer DApp',
            },
            {
                id: 2,
                text: 'Development of Telegram Deployer Bot',
            },
            {
                id: 3,
                text: '$0x404 Token Launch',
            },
            {
                id: 4,
                text: 'Community Building',
            },
        ]
    
    },
    {
        id: 2,
        title: 'PHASE 3',
        class: 'left',
        list : [
            {
                id: 1,
                text: 'Release of Telegram Deployer Bot',
            },
            {
                id: 2,
                text: 'Metadata Generation Tool Rollout',
            },
            {
                id: 3,
                text: 'Initial Partnerships',
            },
            {
                id: 4,
                text: 'Community Engagement',
            },
        ]
    
    },  
    {
        id: 3,
        title: 'PHASE 4',
        class: 'right',
        list : [
            {
                id: 1,
                text: 'Launch of 0x404 Launchpad',
            },
            {
                id: 2,
                text: 'Development of ERC404 Marketplace',
            },
            {
                id: 3,
                text: 'Further Partnerships and Collaborations',
            },
        ]
    
    },      
    {
        id: 4,
        title: 'PHASE 5',
        class: 'left',
        list : [
            {
                id: 1,
                text: 'Launch of ERC404 Marketplace',
            },
            {
                id: 2,
                text: 'Introduction of Specialized Buy Bots',
            },
            {
                id: 3,
                text: 'ERC404 Extensions Implementation',
            },
            {
                id: 4,
                text: 'Global Expansion',
            },
            {
                id: 5,
                text: 'Community and Ecosystem Development',
            },
        ]
    
    },  
    // {
    //     id: 5,
    //     title: 'PHASE 5',
    //     class: 'left',
    //     list : [
    //         {
    //             id: 1,
    //             text: 'Befriending & Chat Functions',
    //         },
    //         {
    //             id: 2,
    //             text: 'Challenging Feature',
    //         },
    //         {
    //             id: 3,
    //             text: 'Corsair Wheel',
    //         },
    //         {
    //             id: 4,
    //             text: 'Mobile Version Launch',
    //         },
    //     ]
    
    // },  
    // {
    //     id: 6,
    //     title: 'PHASE 6',
    //     class: 'right',
    //     list : [
    //         {
    //             id: 1,
    //             text: 'Befriending & Chat Functions',
    //         },
    //         {
    //             id: 2,
    //             text: 'Challenging Feature',
    //         },
    //         {
    //             id: 3,
    //             text: 'Corsair Wheel',
    //         },
    //         {
    //             id: 4,
    //             text: 'Mobile Version Launch',
    //         },
    //     ]
    
    // },  

]

export default dataRoadmap;