import React, { useState, useEffect } from "react";

import { Link, NavLink } from "react-router-dom";
import menus from "../../pages/menu";
import { Dropdown } from "react-bootstrap";

import "./styles.scss";
import logodark from "../../assets/images/logo/logo_dark.png";
import logo from "../../assets/images/logo.png";

import DarkMode from "./DarkMode";
import CommingSoon from "../CommingSoon";

// import Button from '../button';

const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 300);
    });

    return () => {
      setScroll({});
    };
  }, []);

  useEffect(() => {
    var timeout;
    const TemplateViewer = () => {
      // setShowTemplate(true);

      timeout = setTimeout(() => {
        setShowTemplate(false);
      }, 5000);
    };
    TemplateViewer();
    return () => clearTimeout(timeout);
  }, [showTemplate]);

  const [menuActive, setMenuActive] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);
  const handleDropdown = (index) => {
    setActiveIndex(index);
  };

  const handleClickScroll = () => {
    const element = document.getElementById("#faqs");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <header className={`header ${scroll ? "is-fixed" : ""}`}>
      <div
        style={{
          position: "fixed",
          display: showTemplate ? "block" : "none",
          zIndex: 9999,
          right: "2rem",
          top: "2rem",
        }}
      >
        <CommingSoon />
      </div>
      <div className="tf-container">
        <div className="row">
          <div className="col-md-12">
            <div id="site-header-inner">
              <div id="site-logo" className="clearfix">
                <div id="site-logo-inner">
                  <Link to="/" rel="home" className="main-logo">
                    <div className="logo-container">
                      <img
                        id="logo_header"
                        className="logo-dark"
                        src={logo}
                        alt="0x404"
                      />
                      <div className="">0x404</div>
                    </div>
                  </Link>
                </div>
              </div>

              <div className="header-center">
                <nav
                  id="main-nav"
                  className={`main-nav ${menuActive ? "active" : ""}`}
                >
                  <ul id="menu-primary-menu" className="menu">
                    {menus.map((data, idx) => (
                      <li
                        key={idx}
                        style={{ paddingRight: "11px" }}
                        className={`menu-item ${
                          data.namesub ? "menu-item-has-children" : ""
                        } ${activeIndex === idx ? "active" : ""}`}
                      >
                        <a href={data.links}>{data.name}</a>
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
              {/* <button onClick={handleClickScroll}>Redirect</button> */}

              <div className="header-right">
                {/* <DarkMode /> */}
                <a
                  href="https://t.me/eth0x404"
                  target="_blank"
                  style={{ padding: "15px 25px" }}
                  data-toggle="modal"
                  data-target="#popup_bid"
                >
                  <i className="fab fa-telegram-plane"></i>
                  {/* <span>TELEGRAM</span> */}
                </a>

                <a
                  rel="noreferrer"
                  href="https://www.dextools.io/app/en/ether/pair-explorer/0x452c912b4c447f001455f873665db543cd6c48b0"
                  target="_blank"
                  className="tf-button discord"
                >
                  {/* <i className="fab fa-telegram-plane"></i> */}
                  <span>CHART</span>
                </a>
                <Link
                  onClick={() => setShowTemplate(true)}
                  to="#"
                  className="tf-button connect"
                  data-toggle="modal"
                  data-target="#popup_bid"
                >
                  {" "}
                  <span>TELEGRAM BOT</span>
                </Link>
                <div
                  className={`mobile-button ${menuActive ? "active" : ""}`}
                  onClick={handleMenuActive}
                >
                  <span></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
