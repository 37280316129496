import React from "react";

const CommingSoon = () => {
  return (
    <div
      style={{
        height: "4rem",
        backgroundColor: "#141B22",
        padding: '3rem',
        borderBottom:"4px solid #21E786",
        color: "#21E786",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "24px",
        fontWeight: "bolder",
      }}
    >
      Comming Soon!
    </div>
  );
};

export default CommingSoon;
