



import img1 from '../../assets/images/wallet.png'
import img2 from '../../assets/images/info.png'
import img3 from '../../assets/images/gear.png'
import img4 from '../../assets/images/bitcoin.png'


const dataWork = [
    {
        id: 1,
        img: img1,
        title: 'CONNECT THE WALLET',
        text :'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.',
    },
    {
        id: 2,
        img: img2,
        title: 'FILL OUT TOKEN INFO',
        text :'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.',
    },

    {
        id: 3,
        img: img3,
        title: 'GENERATE METADATA URI',
        text :'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.',
    },

    {
        id: 4,
        img: img4,
        title: 'DEPLOY TOKEN',
        text :'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.',
    },

]

export default dataWork;