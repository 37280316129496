import React, { useEffect, useState } from "react";
import Button from "../button/Button";
import Button02 from "../button/Button02";
import panda from "../../assets/images/logo.png";
import { Link } from "react-router-dom";
import CommingSoon from "../CommingSoon";
import { toast } from "react-toastify";
function Banner02(props) {
  const [showTemplate, setShowTemplate] = useState(false);

  useEffect(() => {
    var timeout;
    const TemplateViewer = () => {
      // setShowTemplate(true);

      timeout = setTimeout(() => {
        setShowTemplate(false);
      }, 5000);
    };
    TemplateViewer();
    return () => clearTimeout(timeout);
  }, [showTemplate]);

  return (
    <section id="home" className="tf-slider home2">
      <div
        style={{
          position: "fixed",
          display: showTemplate ? "block" : "none",
          zIndex: 9999,
          right: "2rem",
          top: "2rem",
        }}
      >
        <CommingSoon />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -80%)",
              }}
            >
              <img
                src={panda}
                className="logoImage"
                alt="0X404"
                style={{ height: "27vw" }}
              />
            </div>
            <div className="tf-slider-item style-2">
              <div className="content-inner wow fadeInUp" data-wow-delay="0.4s">
                <h1 className="heading">TOOLKIT FOR ERC404 TOKENS</h1>
                <p className="sub-heading wow fadeInUp" data-wow-delay="0.6s">
                  End to end solution for ERC404 ecosystem
                </p>
                <div className="btn-slider wow fadeInUp" data-wow-delay="0.8s">
                  <a
                    href="https://deployer.0x404.live/"
                    target="_blank"
                    className="tf-button style-1"
                    data-toggle="modal"
                    data-target="#popup_bid"
                    style={{ cursor: "pointer" }}
                  >
                    <span>ENTER DAPP</span>
                  </a>

                  <a
                    href="https://0x404.gitbook.io/0x404/"
                    target="_blank"
                    className="tf-button style-2"
                    data-toggle="modal"
                    data-target="#popup_bid"
                  >
                    <span>WHITEPAPER</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Banner02;
