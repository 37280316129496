import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

Work02.propTypes = {
  data: PropTypes.array,
};

function Work02(props) {
  const { data } = props;
  return (
    <section id="functionality" className="tf-work-ss">
      <div className="tf-container">
        <div className="row">
          <div className="col-md-12">
            <div className="title-ss wow fadeInUp">
              <h3>
                HOW <span>0X404</span> DEPLOYER WORKS
              </h3>
            </div>
          </div>
          {data.map((idx) => (
            <div key={idx.id} className="col-xl-3 col-md-6 ">
              <div className="tf-work style-2 wow fadeInUp">
                <div className="image">
                  <img id="work-5" src={idx.img} alt="0X404" style={{height: '12rem'}} />
                </div>
                <h5 className="step">STEP {idx.id}</h5>
                <h4 className="title">
                  <Link to="#">{idx.title}</Link>
                </h4>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Work02;
