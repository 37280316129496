



import img1 from '../images/img1.jpg'
import img2 from '../images/img2.jpg'
import img3 from '../images/img3.jpg'
import img4 from '../images/img4.jpg'
import logo from "../../assets/images/logo (1).jpg"
// import img5 from '../images/img5.jpg'
// import img6 from '../images/img6.jpg'
// import img7 from '../images/img7.jpg'


const dataTeam = [
    {
        id: 1,
        img: logo,
        name: 'Trent',
        position :'Founder',
        social: [
            // {
            //     id: 1,
            //     icon: 'fab fa-linkedin'
            // },
            // {
            //     id: 2,
            //     icon: 'fab fa-facebook'
            // },
            // {
            //     id: 3,
            //     icon: 'fab fa-instagram'
            // },
            {
                id: 4,
                icon: 'fab fa-telegram',
                link: "https://t.me/jabjub7"
            },
        ]
    },
    {
        id: 2,
        img: logo,
        name: 'CryptoMan',
        position :'Business Development',
        social: [
            // {
            //     id: 1,
            //     icon: 'fab fa-linkedin'
            // },
            // {
            //     id: 2,
            //     icon: 'fab fa-facebook'
            // },
            // {
            //     id: 3,
            //     icon: 'fab fa-instagram'
            // },
            {
                id: 4,
                icon: 'fab fa-telegram',
                link: "https://t.me/cr7suiiie"
            },
        ]
    },
    {
        id: 3,
        img: logo,
        name: 'Kacper',
        position :'Backend Engineer',
        social: [
            // {
            //     id: 1,
            //     icon: 'fab fa-linkedin'
            // },
            // {
            //     id: 2,
            //     icon: 'fab fa-facebook'
            // },
            // {
            //     id: 3,
            //     icon: 'fab fa-instagram'
            // },
            {
                id: 4,
                icon: 'fab fa-telegram',
                link: "https://t.me/jyejsi76"
            },
        ]
    },
    {
        id: 4,
        img: logo,
        name: 'Stephanie Wilson',
        position :'Front End Engineer',
        social: [
            // {
            //     id: 1,
            //     icon: 'fab fa-linkedin'
            // },
            // {
            //     id: 2,
            //     icon: 'fab fa-facebook'
            // },
            // {
            //     id: 3,
            //     icon: 'fab fa-instagram'
            // },
            {
                id: 4,
                icon: 'fab fa-telegram',
                link: "https://t.me/wilsonsteph7"
            },
        ]
    },
    {
        id: 5,
        // img: img5,
        name: 'Jenny Wilson',
        position :'Artist',
        social: [
            {
                id: 1,
                icon: 'fab fa-linkedin'
            },
            {
                id: 2,
                icon: 'fab fa-facebook'
            },
            {
                id: 3,
                icon: 'fab fa-instagram'
            },
            {
                id: 4,
                icon: 'fab fa-telegram'
            },
        ]
    },
    {
        id: 6,
        // img: img6,
        name: 'Robert Fox',
        position :'UI/UX Designer',
        social: [
            {
                id: 1,
                icon: 'fab fa-linkedin'
            },
            {
                id: 2,
                icon: 'fab fa-facebook'
            },
            {
                id: 3,
                icon: 'fab fa-instagram'
            },
            {
                id: 4,
                icon: 'fab fa-telegram'
            },
        ]
    },
    {
        id: 7,
        // img: img7,
        name: 'Devon Lane',
        position :'Ux Architect',
        social: [
            {
                id: 1,
                icon: 'fab fa-linkedin'
            },
            {
                id: 2,
                icon: 'fab fa-facebook'
            },
            {
                id: 3,
                icon: 'fab fa-instagram'
            },
            {
                id: 4,
                icon: 'fab fa-telegram'
            },
        ]
    },

]

export default dataTeam;