const menus = [
    {
        id: 1,
        name: 'Home',
        links: '#home',
    },
    {
        id: 2,
        name: 'Functionality',
        links: '#functionality', 
    },
    {
        id: 4,
        name: 'Road Map',
        links: '#roadmap',
      
    },
    {
        id: 5,
        name: 'Team',
        links: '#team',
    },
    {
        id: 6,
        name: 'FAQs',
        links: '#faqs',
    },

    {
        id: 7,
        name: 'Contact',
        links: '#contact',
    },
    
]

export default menus;